<template>
  <v-dialog v-model="form.show" max-width="500px" persistent>
    <v-card :loading="save_data">
      <v-card-title class="text-h6 grey lighten-2">
        {{ getTitle() }}
      </v-card-title>
      <v-card-text class="justify-center">
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-form class="mt-8">


                <v-select label="Роль"
                          v-model="roles_select"
                          :items="roles"
                          item-text="[1]"
                          item-value="[0]"
                          return-object
                ></v-select>

                <div v-if="!form.item_edit">
                  <v-text-field label="Имя, userID, steamID"
                                v-model="search"
                                append-icon="search"
                                single-line
                                hide-details
                                :loading="loading"
                  >
                  </v-text-field>
                  <v-divider/>
                  <div class="my-5">
                    <v-btn @click="confirmUser" x-large elevation="1" :loading="loading" min-width="170px">
                      {{ search_user.name }}
                    </v-btn>
                  </div>
                </div>


              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="form.show = false">Cancel</v-btn>
        <v-btn color="blue darken-1" :disabled="saveBlock" text
               :loading="save_data"
               @click="formOK">
          OK
        </v-btn>
        <!--          <v-spacer></v-spacer>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

let normal = {}
// function initialState (){
//   return {
//     modalBodyDisplay: 'getUserInput',
//     submitButtonText: 'Lookup',
//     addressToConfirm: null,
//     bestViewedByTheseBounds: null,
//     location:{
//       name: null,
//       address: null,
//       position: null
//     }
//   }
// }

export default {
  props: ['form'],
  // props: {form: {type: Object}},
  data: function () {
    return {
      search: '',
      search_user: {name: '', uid: ''},
      item_edit: {
        user: {
          username: 'пусто'
        }
      },
      roles_select: null,
      roles: [],
      loading: false,
      save_data: false,
      confirm_user: false,
    }
  },
  watch: {
    search() {
      this.find_user();
      // this.confirm_user = false
    },
    form: {
      // immediate: true, // немедленный запуск обработки
      deep: true, // глубокое наблюдение за объектом
      handler(form) {
        // window.console.log("========  watch Онлайн  ===========", form)
        this.clear()
        if (!form.item_edit) return
        this.item_edit = this.$lib.copy(form.item_edit)
        this.roles_select = [this.item_edit.role]
      }
    }
  },
  created() {
    this.rolesLoad()
  },
  methods: {
    rolesLoad() {
      this.$axios.get(this.$url.core_project_roles)
          .then(response => {
            this.roles = response.data
            normal = this.$lib.copy(this.$data)
          })
    },
    getTitle() {
      if (!this.form.item_edit) return "Новый пользователь"
      return this.item_edit.user.username
    },
    confirmUser() {
      if (!this.search_user.usid) return
      window.console.log('== confirmUser search_user', this.search_user)
      this.search = this.search_user.usid
      this.confirm_user = true
    },
    find_user() {
      // window.console.log('search', this.search)
      if (this.search.length < 2) return
      this.loading = true
      this.$axios.get(this.$url.core_project_find_user, {params: {search: this.search}})
          .then(response => {
            this.search_user = response.data
          })
          .finally(() => {
            this.loading = false;
          })
    },
    itemCreate() {
      this.save_data = true;
      this.$axios.post(this.$url.core_project_users+'111', {
        role: this.roles_select[0],
        usid: this.search_user.usid
      })
          .then(response => {
            this.form.show = false
            this.$emit('saved', response.data)
          })
          .finally(() => {
            this.save_data = false;
          })
    },
    itemUpdate() {
      this.save_data = true;
      this.$axios.patch(this.$url.core_project_users + `${this.item_edit.id}/`,
          {
            role: this.roles_select[0]
          })
          .then(response => {
            this.form.show = false
            this.$emit('saved', response.data)
          })
          .finally(() => {
            this.save_data = false;
          })
    },
    formOK() {
      if (this.form.item_edit) this.itemUpdate()
      else this.itemCreate()
    },
    clear() {
      Object.assign(this.$data, normal);
    }
  },
  computed: {
    saveBlock: function () {
      if (this.form.item_edit) { // редактируем элемент
        if ((!this.roles_select) || (this.roles_select[0] === this.form.item_edit.role)) return true
      }
      if (!this.form.item_edit) { // создаем элемент
        if (!this.roles_select) return true
        if (!this.confirm_user) return true
      }
      return false
    },
  }
}
</script>
